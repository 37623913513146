import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { audit, auditBaseUrl } from '../endpoints';
// Define a service using a base URL and expected endpoints
  export const auditService = createApi({
    reducerPath: 'auditService',
    baseQuery: fetchBaseQuery({ baseUrl: auditBaseUrl }),
    tagTypes: ['Audit'],
    endpoints: (builder) => ({
        getAllAudits: builder.mutation({
            query(body) {
                return {
                    url: `${audit.auditController.getAllAudits}/${body.payload.page}/${body.payload.size}?orderBy=${body.payload.orderBy}`,
                    method: 'GET',
                    headers: { Authorization: `Bearer ${body.token}`},
                }
            }
        }),
        getAuditById: builder.mutation({
            query(body) {
                return {
                    url: `${audit.auditController.getAuditById}/${body.id}`,
                    method: 'GET',
                    headers: { Authorization: `Bearer ${body.token}`},
                }
            }
        }),
        saveAudit: builder.mutation({
            query(body){
                return {
                    url: `${audit.auditController.saveAudit}`,
                    method: 'POST',
                    headers: { Authorization: `Bearer ${body.token}`},
                    body: body.payload,
                }
            }
        }),
        updateAudit: builder.mutation({
            query(body){
                return {
                    url: `${audit.auditController.updateAudit}/${body.id}`,
                    method: 'PUT',
                    headers: { Authorization: `Bearer ${body.token}`},
                    body: body.payload,
                }
            }
        }),
        deleteAudit: builder.mutation({
            query(body){
                return {
                    url: `${audit.auditController.deleteAudit}/${body.id}`,
                    method: 'DELETE',
                    headers: { Authorization: `Bearer ${body.token}`},
                }
            }
        }),
        getAuditBySearch: builder.mutation({
            query(body) {
                return {
                    url: `${audit.auditController.getAuditBySearch}`,
                    method: "POST",
                    headers: { Authorization: `Bearer ${body.token}`},
                    body: body.payload,
                };
            },
        }),
        findAllAudits: builder.mutation({
            query(body) {
                return {
                    url: `${audit.auditController.findAllAudits}`,
                    method: 'GET',
                    headers: { Authorization: `Bearer ${body.token}`},
                }
            }
        }),
        getAuditsBasedOnUser: builder.mutation({
            query(body) {
                return {
                    url: `${audit.auditController.getAuditsBasedOnUser}/${body.payload.page}/${body.payload.size}?orderBy=${body.payload.orderBy}&userId=${body.payload.userId}`,
                    method: 'GET',
                    headers: { Authorization: `Bearer ${body.token}`},
                }
            }
        }),
        getAuditsBasedOnOrganization: builder.mutation({
            // query(body) {
            //     return {
            //         url: `${audit.auditController.getAuditsByOrganization}/${body.payload.page}/${body.payload.size}?orderBy=${body.payload.orderBy}&organizationName=${body.payload.organizationName}`,
            //         method: 'GET',
            //         headers: { Authorization: `Bearer ${body.token}`},
            //     }
            // }
            query(body) {
                return {
                    url: `${audit.auditController.getAuditsByOrganization}`,
                    method: "POST",
                    headers: { Authorization: `Bearer ${body.token}`},
                    body: body.payload,
                };
            },
        }),
    })
  });
  export const {
    useGetAllAuditsMutation,
    useSaveAuditMutation,
    useUpdateAuditMutation,
    useDeleteAuditMutation,
    useGetAuditByIdMutation,
    useGetAuditBySearchMutation,
    useFindAllAuditsMutation,
    useGetAuditsBasedOnUserMutation,
    useGetAuditsBasedOnOrganizationMutation,
  } = auditService;