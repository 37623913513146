import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import { authService } from "./services/authService";
import authReducer from "./slices/authSlice";
import allUserReducer from "./slices/allUserSlice";
import { allUserService } from "./services/allUserService";
import dimensionMasterReducer from "./slices/dimensionMasterSlice";
import { dimensionMasterService } from "./services/dimensionMasterService";
import processMasterReducer from "./slices/processMasterSlice";
import { processMasterService } from "./services/processMasterService";
import auditReducer from "./slices/auditSlice";
import { auditFrameworkService } from "./services/auditFrameworkService";
import { auditService } from "./services/auditService";
import auditFrameworkReducer from "./slices/auditFrameworkSlice";
import companyReducer from "./slices/companySlice";
import { companyService } from "./services/companyService";
import groupMasterRreducer from "./slices/groupMasterSlice";
import { groupMasterService } from "./services/groupMasterService";
import generatePdfRreducer from "./slices/pdfSlice";
import { pdfService } from "./services/pdfService";
import { allUserRoleService } from "./services/userRoleService";
import { allUserGroupService } from "./services/userGroupService";
import allUserRoleReducer from "./slices/allUserRoleSlice";
import allUserGroupReducer from "./slices/allUserGroupSlice";
import statesReducer from "./slices/statesSlice";
import { stateService } from "./services/stateService";
import countryReducer from "./slices/countrySlice";
import { countryService } from "./services/countryService";
import packageMasterReducer from "./slices/packageMasterSlice";
import { packageMasterService } from "./services/packageMasterService";
import { organizationMasterService } from "./services/organizationMasterService";
// import cityReducer from "./slices/citySlice";
// import { cityService } from "./services/cityService";
import organizationMasterReducer from "./slices/organizationMasterSlice";

export const store = configureStore({
  reducer: {
    // Slices
    auth: authReducer,
    allUser: allUserReducer,
    states: statesReducer,
    country: countryReducer,
    // city: cityReducer,
    dimensionMaster: dimensionMasterReducer,
    processMaster: processMasterReducer,
    audit: auditReducer,
    auditFramework: auditFrameworkReducer,
    company: companyReducer,
    groupMaster: groupMasterRreducer,
    generatePdf: generatePdfRreducer, 
    allUserRole: allUserRoleReducer,
    allUserGroup: allUserGroupReducer,
    packageMaster: packageMasterReducer,
    organizationMaster: organizationMasterReducer,
  
    // Services
    [authService.reducerPath]: authService.reducer,
    [allUserService.reducerPath]: allUserService.reducer,
    [dimensionMasterService.reducerPath]: dimensionMasterService.reducer,
    [processMasterService.reducerPath]: processMasterService.reducer,
    [auditService.reducerPath]: auditService.reducer,
    [auditFrameworkService.reducerPath]: auditFrameworkService.reducer,
    [companyService.reducerPath]: companyService.reducer,
    [groupMasterService.reducerPath]: groupMasterService.reducer,
    [pdfService.reducerPath]: pdfService.reducer,
    [allUserRoleService.reducerPath]: allUserRoleService.reducer,
    [allUserGroupService.reducerPath]: allUserGroupService.reducer,
    [stateService.reducerPath]: stateService.reducer,
    [countryService.reducerPath]: countryService.reducer,
    // [cityService.reducerPath]: cityService.reducer,
    [packageMasterService.reducerPath]: packageMasterService.reducer,
    [organizationMasterService.reducerPath]: organizationMasterService.reducer,

  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    })
      .concat(authService.middleware)
      .concat(allUserService.middleware)  
      .concat(dimensionMasterService.middleware)
      .concat(processMasterService.middleware)
      .concat(auditService.middleware)
      .concat(auditFrameworkService.middleware)
      .concat(companyService.middleware)
      .concat(groupMasterService.middleware)
      .concat(pdfService.middleware)
      .concat(allUserRoleService.middleware)
      .concat(allUserGroupService.middleware)
      .concat(stateService.middleware)
      .concat(countryService.middleware)
      // .concat(cityService.middleware)
      .concat(packageMasterService.middleware)
      .concat(organizationMasterService.middleware)
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
