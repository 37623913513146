import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

export interface statesState {
  data: {} | null | any;
  totalRecords: number | null; //total elements
  totalPages: number | null;
  currentPage: number | null; //number
}

const initialState: statesState = {
  data: null,
  totalRecords: null,
  totalPages: null,
  currentPage: null,
};

export const statesSlice = createSlice({
  name: "states",
  initialState,
  reducers: {
    setStates: (state, action) => {
      state.data = action.payload.data;
    },
    setAllStatesWithPagination: (state, action) => {
      state.data = action.payload.data.data;
      state.totalRecords = action.payload.data.totalRecords;
      state.totalPages = action.payload.data.totalPage;
      state.currentPage = action.payload.data.currentPage;
    },
    addNewStates: (state: any, action) => {
      state.data = [action.payload, ...state.data];
    },
    reset: (state) => {
      state = initialState;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setStates,setAllStatesWithPagination, addNewStates, reset } = statesSlice.actions;
export const getFindStateByCountryNameDetail = (state: RootState) =>
  state.states.data;
export const getAllStatesWithPaginationDetails = (state: RootState) =>
  state.states.data;
export const getAllStatesTotalRecords = (state: RootState) =>
  state.states.totalRecords;

export default statesSlice.reducer;
