import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import "./login.scss";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { alertMessage } from "../../utils/AlertService";
import { Form, Modal } from "react-bootstrap";
import { setForgotPassword, setResetPassword } from "../../redux-toolkit/slices/allUserSlice";
import { useForgotPasswordMutation } from "../../redux-toolkit/services/allUserService";
import { useResetPasswordMutation } from "../../redux-toolkit/services/allUserService";
import * as Yup from 'yup';
import { getLocalStorageData } from "../../utils/LocalStorageService";
import { PAGE_TITLE } from "../../constant/reportType";
import Loading from "../../components/Loading";

type Props = {
    forgotResetShow?: any;
    setForgotResetShow?: any;
    title?: string;
};

const ForgotResetPassword = (props: Props) => {

    const resetPasswordSchema = Yup.object().shape({
        newPassword: Yup.string()
            .required("Password is required")
            .min(8, "Password is too short")
            .matches(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                "Password must be min 8 characters, and have 1 letter, number, special character"),

        confirmPassword: Yup.string()
            .required("Confirm Password is required")
            .oneOf([Yup.ref("newPassword")], "Password must match"),
    });

    const forgotPasswordSchema = Yup.object().shape({
        email: Yup.string()
            .required(' Email is required').trim(),
    });
    //selector


    //Mutation
    const [forgotPassword, forgotPasswordResponse] = useForgotPasswordMutation();
    const [resetPassword, resetPasswordResponse] = useResetPasswordMutation();

    //states
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const localUserObj = getLocalStorageData("localUser");

    const handleSubmitForm = async (values: any) => {
        if (props.title === PAGE_TITLE.FORGOT_PASSWORD) {
            forgotPassword({
                email: values.email
            });
        } else if (props.title === PAGE_TITLE.RESET_PASSWORD) {
            resetPassword({
                password: values.confirmPassword,
                userId: localUserObj && localUserObj.id
            });
        }
    };

    //forgot password
    useEffect(() => {
        if (props.title === PAGE_TITLE.FORGOT_PASSWORD) {
            if (forgotPasswordResponse.isSuccess) {
                dispatch(setForgotPassword(forgotPasswordResponse));
                alertMessage("Password sent to given email address", "success");
                props.setForgotResetShow(false)
            } else if (forgotPasswordResponse.isError) {
                // let response: any = forgotPasswordResponse.error.data.serviceErrors[0].msg;
                alertMessage("Error while submit", "error");
                props.setForgotResetShow(false)
            }
        }
    }, [forgotPasswordResponse.isSuccess, forgotPasswordResponse.isError]);

    // reset password
    useEffect(() => {
        if (props.title === PAGE_TITLE.RESET_PASSWORD) {
            if (resetPasswordResponse.isSuccess) {
                dispatch(setResetPassword(resetPasswordResponse));
                alertMessage("Password reset successfully", "success");
                props.setForgotResetShow(false)
                navigate("logout");
            } else if (resetPasswordResponse.isError) {
                alertMessage("Error while password reset", "error");
                props.setForgotResetShow(false)
            }
        }
    }, [resetPasswordResponse.isSuccess, resetPasswordResponse.isError]);

    const [isShown, setIsSHown] = useState(false);

    const togglePassword = () => {
        setIsSHown((isShown) => !isShown);
    };

    const handleClose = () => props.setForgotResetShow(false);

    return (
        <>
            {forgotPasswordResponse.isLoading ||
                resetPasswordResponse.isLoading
                ? <Loading /> : ""}

            <Modal
                aria-labelledby="forgotPassword-modal"
                show={props.forgotResetShow}
                onHide={handleClose}
                className="login"
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h4 className="text-align-center fw-bold text-cm-primary1">{props.title}</h4>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Formik
                        initialValues={props.title === PAGE_TITLE.FORGOT_PASSWORD ? {
                            email: "",
                        } : {
                            newPassword: "",
                            confirmPassword: "",
                        }}
                        onSubmit={handleSubmitForm}
                        validationSchema={props.title === PAGE_TITLE.FORGOT_PASSWORD ? forgotPasswordSchema : resetPasswordSchema}
                    >
                        {({
                            values,
                            errors,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                        }) => (
                            <Form onSubmit={handleSubmit}>
                                <div className="d-flex flex-column rounded-3 overflow-hidden p-md-4 px-3 pt-md-0 pt-0 pb-2">
                                    {props.title === PAGE_TITLE.FORGOT_PASSWORD ? (
                                        <div className="mb-3">
                                            <div className="d-flex">
                                                <div>Email</div>
                                                <div className="text-danger">*</div>
                                            </div>
                                            <input
                                                type="email"
                                                value={values.email}
                                                name="email"
                                                onChange={handleChange}
                                                className={`form-control form-textbox bg-secondary-light border-secondary-light ${errors.email ? 'is-invalid' : ''}`}
                                                id="email"
                                                aria-describedby="email"
                                                placeholder="xyz@abc.com"
                                            />
                                        </div>
                                    ) : (
                                        <>
                                            <div className="">
                                                <Form.Label htmlFor="newPassword">New Password</Form.Label>
                                                <Form.Control
                                                    type="password"
                                                    id="newPassword"
                                                    aria-describedby="passwordHelpBlock"
                                                    placeholder="Enter new password"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name="newPassword"
                                                    value={values.newPassword}
                                                    className={`form-control ${errors.newPassword ? 'is-invalid' : ''}`}
                                                />
                                                <div className="invalid-feedback">{errors.newPassword}</div>
                                            </div>
                                            <div className="">
                                                <Form.Label htmlFor="inputPassword5">
                                                    Confirm Password
                                                </Form.Label>
                                                <Form.Control
                                                    type="password"
                                                    id="confirmPassword"
                                                    aria-describedby="passwordHelpBlock"
                                                    placeholder="Confirm your new password"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    name="confirmPassword"
                                                    value={values.confirmPassword}
                                                    className={`form-control ${errors.confirmPassword ? 'is-invalid' : ''}`}
                                                />
                                                <div className="invalid-feedback">{errors.confirmPassword}</div>
                                            </div>
                                            <div>
                                                <h5>Password Requirements:</h5>
                                                <ul>
                                                    <li>Minimum 8 characters long - the more, the better.</li>
                                                    <li>Atleast one one letter.</li>
                                                    <li>Atleast one number, or special character.</li>
                                                </ul>
                                            </div>
                                        </>
                                    )}

                                    <div className="d-grid gap-2">
                                        <button
                                            type="submit"
                                            className="btn btn-danger mt-3 mx-2 "
                                        >
                                            Submit
                                        </button>
                                    </div>
                                </div>

                            </Form>
                        )}
                    </Formik>
                </Modal.Body>
            </Modal>
        </>
    );
};
export default ForgotResetPassword;