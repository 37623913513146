import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { userManagementBaseUrl, userManagement} from "../endpoints";
// Define a service using a base URL and expected endpoints
export const allUserRoleService = createApi({
    reducerPath: "allUserRoleService",
    baseQuery: fetchBaseQuery({ baseUrl: userManagementBaseUrl }),
    tagTypes: ["allUserRole"],
    endpoints: (builder) => ({
        getAllUserRole: builder.mutation({
            query(body) {
                return {
                    url: `${userManagement.userRoleController.getAllUserRole}`,
                    method: "GET",
                    // headers: { Authorization: `Bearer ${body.token}`},
                    // body: body.payload,
                };
            },
        }),
        updateUserRole: builder.mutation({
            query(body) {
                return {
                    url: `${userManagement.userRoleController.updateUserRole}`,
                    method: "PUT",
                    headers: { Authorization: `Bearer ${body.token}`},
                    body: body.payload,
                };
            },
        }),
        deleteUserRoleById: builder.mutation({
            query(body) {
                return {
                    url: `${userManagement.userRoleController.deleteUserRoleById}/${body.id}`,
                    method: "DELETE",
                    headers: { Authorization: `Bearer ${body.token}`},
                };
            },
        }),
        getUserRoleById: builder.mutation({
            query(body) {
                return {
                    url: `${userManagement.userRoleController.getUserRoleById}/${body.id}`,
                    method: "GET",
                    headers: { Authorization: `Bearer ${body.token}`},
                };
            },
        }),
        saveUserRole: builder.mutation({
            query(body) {
                return {
                    url: `${userManagement.userRoleController.saveUserRole}`,
                    method: "POST",
                    headers: { Authorization: `Bearer ${body.token}`},
                    body: body.payload,
                };
            },
        }),
    }),
});
export const {
    useGetAllUserRoleMutation,
    useSaveUserRoleMutation,
    useDeleteUserRoleByIdMutation,
    useGetUserRoleByIdMutation,
    useUpdateUserRoleMutation,
} = allUserRoleService;