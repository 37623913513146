import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
export interface AuditState {
  data: [] | null | any;
  currentPage: number | null;
  totalRecords: number | null;
  totalPages: number | null;
  allAudit: [] | null | any;
  auditByUser: [] | null | any;
  audisBasedOnOrganization: [] | null | any;
  audisBasedOnOrgTotalRecords: number | null;
}
const initialState: AuditState = {
  data: null,
  totalRecords: null,
  totalPages: null,
  currentPage: null,
  allAudit: null,
  auditByUser: null,
  audisBasedOnOrganization: null,
  audisBasedOnOrgTotalRecords: null,
};
export const auditSlice = createSlice({
  name: 'audit',
  initialState,
  reducers: {
    setAllAudit: (state, action) => {
      state.allAudit = action.payload.data;
    },
    setAudit: (state, action) => {
      state.data = action.payload.data.data;
      state.totalRecords = action.payload.data.totalRecords;
      state.totalPages = action.payload.data.totalPages;
      state.currentPage = action.payload.data.currentPage;
    },
    setAuditsByUser: (state, action) => {
      state.data = action.payload.data.data;
      state.totalRecords = action.payload.data.totalRecords;
      state.totalPages = action.payload.data.totalPages;
      state.currentPage = action.payload.data.currentPage;
    },
    setAuditsBasedOnOrganization: (state, action) => {
      state.data = action.payload.data.data;
      state.audisBasedOnOrgTotalRecords = action.payload.data.totalRecords;
      state.totalPages = action.payload.data.totalPages;
      state.currentPage = action.payload.data.currentPage;
    },
    setAllAuditBySearch: (state, action) => {
      state.data = action.payload.data.data;
      state.totalRecords = action.payload.data.totalRecords;
      state.totalPages = action.payload.data.totalPages;
      state.currentPage = action.payload.data.currentPage;
    },
    reset: (state) => {
      state = initialState;
    },
  },
});
// Action creators are generated for each case reducer function
export const { setAllAudit, setAudit, setAllAuditBySearch, setAuditsByUser, setAuditsBasedOnOrganization,reset } = auditSlice.actions;
export const allAuditDetails = (state: RootState) => state.audit.allAudit;
export const auditByUserDetails = (state: RootState) => state.audit.auditByUser;
export const getAuditsByUserTotalRecords = (state: RootState) => state.audit.totalRecords;
export const auditBasedOnOrganization = (state: RootState) => state.audit.audisBasedOnOrganization;
export const getAuditsBasedOnOrgTotalRecords = (state: RootState) => state.audit.audisBasedOnOrgTotalRecords;
export const getAllAuditDetails = (state: RootState) => state.audit.data;
export const getAuditTotalRecords=(state:RootState)=>state.audit.totalRecords;
export const getAllAuditBySearch = (state: RootState) => state.audit.data;
export default auditSlice.reducer;