import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

export interface countryState {
    data: {} | null | any;
    getAllCountry: null;
}

const initialState: countryState = {
    data: null,  
    getAllCountry: null,
};

export const countrySlice = createSlice({
    name: 'country',
    initialState,
    reducers: {
        setcountry: (state, action) => {
            state.data = action.payload.data;
        },
        addNewcountry: (state: any, action) => {
            state.data = [action.payload, ...state.data];
        },
        reset: (state) => {
            state = initialState;
        },
    },
});

// Action creators are generated for each case reducer function
export const { setcountry,addNewcountry, reset,  } = countrySlice.actions;
export const getAllcountryDetail = (state: RootState) => state.country.data;
export default countrySlice.reducer;

