import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { audit, auditBaseUrl } from '../endpoints';
// Define a service using a base URL and expected endpoints
  export const auditFrameworkService = createApi({
    reducerPath: 'auditFrameworkService',
    baseQuery: fetchBaseQuery({ baseUrl: auditBaseUrl }),
    tagTypes: ['AuditFramewor'],
    endpoints: (builder) => ({
        getAllAuditFramework: builder.mutation({
            query(body) {
                return {
                    url: `${audit.auditFrameworkController.getAllAuditFramework}/${body.payload.page}/${body.payload.size}?orderBy=${body.payload.orderBy}`,
                    method: 'GET',
                    headers: { Authorization: `Bearer ${body.token}`},
                }
            }
        }),
        getAuditFrameworkById: builder.mutation({
            query(body) {
                return {
                    url: `${audit.auditFrameworkController.getAuditFrameworkById}/${body.id}`,
                    method: 'GET',
                    headers: { Authorization: `Bearer ${body.token}`},
                }
            }
        }),
        saveAuditFramework: builder.mutation({
            query(body){
                return {
                    url: `${audit.auditFrameworkController.saveAuditFramework}`,
                    method: 'POST',
                    headers: { Authorization: `Bearer ${body.token}`},
                    body: body.payload,
                }
            }
        }),
        updateAuditFramework: builder.mutation({
            query(body){
                return {
                    url: `${audit.auditFrameworkController.updateAuditFramework}/${body.id}`,
                    method: 'PUT',
                    headers: { Authorization: `Bearer ${body.token}`},
                    body: body.payload,
                }
            }
        }),
        deleteAuditFramework: builder.mutation({
            query(body){
                return {
                    url: `${audit.auditFrameworkController.deleteAuditFramework}/${body.id}`,
                    method: 'DELETE',
                    headers: { Authorization: `Bearer ${body.token}`},
                }
            }
        }),
        getAuditFrameworkBySearch: builder.mutation({
            query(body) {
                return {
                    url: `${audit.auditFrameworkController.getAuditFrameworkBySearch}`,
                    method: "POST",
                    headers: { Authorization: `Bearer ${body.token}`},
                    body: body.payload,
                };
            },
        }),
        findAllAuditFramework: builder.mutation({
            query(body) {
                return {
                    url: `${audit.auditFrameworkController.findAllAuditFramework}`,
                    method: 'GET',
                    headers: { Authorization: `Bearer ${body.token}`},
                }
            }
        }),
        uploadXlsx: builder.mutation({
            query(body) {
                const formData = new FormData();
                formData.append('file', body.file);

                return {
                    url: `${audit.auditFrameworkController.uploadXlsx}`,
                    method: 'POST',
                    headers: { Authorization: `Bearer ${body.token}`},
                    body: formData,
                }
            }
        }),
        getAllAuditFrameworkName: builder.mutation({
            query(body) {
                return {
                    url: `${audit.auditFrameworkController.getAllAuditFrameworkName}`,
                    method: 'GET',
                    headers: { Authorization: `Bearer ${body.token}`},
                }
            }
        }),
    })
  });
  export const {
    useGetAllAuditFrameworkMutation,
    useSaveAuditFrameworkMutation,
    useUpdateAuditFrameworkMutation,
    useDeleteAuditFrameworkMutation,
    useGetAuditFrameworkByIdMutation,
    useGetAuditFrameworkBySearchMutation,
    useFindAllAuditFrameworkMutation,
    useUploadXlsxMutation,
    useGetAllAuditFrameworkNameMutation,
  } = auditFrameworkService;