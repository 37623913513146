import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
export interface AllUserRoleState {
    data: [] | null | any;
}
const initialState: AllUserRoleState = {
    data: null,
};
export const allUserRoleSlice = createSlice({
    name: "allUserRole",
    initialState,
    reducers: {
        setAllUserRole: (state, action) => {
            state.data = action.payload.data;
        },
        reset: (state) => {
            state = initialState;
        },
    },
});
// Action creators are generated for each case reducer function
export const { setAllUserRole, reset } = allUserRoleSlice.actions;
export const getAllUserRoleDetails = (state: RootState) => state.allUserRole.data;
export default allUserRoleSlice.reducer;