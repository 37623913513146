import { toast } from "react-toastify";

export type AlertTypes = "info" | "success" | "warning" | "error";
export type ThemeTypes = "light" | "dark" | "colored";

export const alertMessage = (
  message: string,
  type: AlertTypes,
  themeType: ThemeTypes = "light"
) => {
  switch (type) {
    case "info":
      toast.info(message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: themeType
      });
      break;
    case "success":
      toast.success(message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: themeType
      });
      break;
    case "warning":
      toast.warning(message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: themeType
      });
      break;
    case "error":
      toast.error(message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: themeType
      });
      break;

    default:
      toast.info(message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: themeType
      });
      break;
  }
};
