export enum ACTION_TYPE {
    ADD_ACTION = "ADD_ACTION",
    UPDATE_ACTION = "UPDATE_ACTION"
}

export enum REPORT_NAME {
    DIMENSION_MASTER = "Dimension Master",
    PROCESS_MASTER = "Process Master",
    AUDIT_FRAMEWORK = "Audit Framework",
    AUDIT = "Audit",
    GROUP_MASTER = "Group Master",
    ORGANIZATION_MASTER = "Organization Master",
    USER_MASTER = "User Master",
    USER_GROUP = "User Group",
    USER_ROLE = "User Role",
}

export enum REPORT_PATH {
    DIMENSION_MASTER = "dimensionMaster",
    PROCESS_MASTER = "processMaster",
    AUDIT_FRAMEWORK = "auditFramework",
    AUDIT = "audit",
    GROUP_MASTER = "groupMaster",
    ORGANIZATION_MASTER = "organizationMaster",
    USER_MASTER = "userMaster",
    USER_GROUP = "userGroup",
    USER_ROLE = "userRole",
}

export enum PAGE_TITLE {
    FORGOT_PASSWORD = "Forgot Password",
    RESET_PASSWORD = "Reset Password",
}