import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
export interface AllUserState {
  data: [] | null | any;
  resetPasswordData: null | null;
  forgotPasswordData: null | null;
  totalRecords: number | null; //total elements
  totalPages: number | null;
  currentPage: number | null; //number
  findUserByIdData: null | null;
}
const initialState: AllUserState = {
  data: null,
  resetPasswordData: null,
  forgotPasswordData: null,
  totalRecords: null,
  totalPages: null,
  currentPage: null,
  findUserByIdData: null,
};
export const allUserSlice = createSlice({
  name: "allUser",
  initialState,
  reducers: {
    setAllUser: (state, action) => {
      state.data = action.payload.data.data;
    },
    setAllUserWithPagination: (state, action) => {
      state.data = action.payload.data.data;
      state.totalRecords = action.payload.data.totalRecords;
      state.totalPages = action.payload.data.totalPage;
      state.currentPage = action.payload.data.currentPage;
    },
    setFindUserByIdUser: (state, action) => {
        state.findUserByIdData = action.payload.data;
    },
    setForgotPassword: (state, action) => {
      state.forgotPasswordData = action.payload.data;
    },
    setResetPassword: (state, action) => {
      state.resetPasswordData = action.payload.data;
    },
    reset: (state) => {
      state = initialState;
    },
  },
});
// Action creators are generated for each case reducer function
export const {
  setAllUser,
  setForgotPassword,
  setAllUserWithPagination,
  setResetPassword,
  setFindUserByIdUser,
  reset,
} = allUserSlice.actions;
export const getAllUserDetails = (state: RootState) => state.allUser.data;
export const getFindUserByIdDetails = (state: RootState) => state.allUser.findUserByIdData;
export const getForgotPasswordDetails = (state: RootState) => state.allUser.forgotPasswordData;
export const getResetPasswordDetails = (state: RootState) => state.allUser.resetPasswordData;
export const getAllUsersTotalRecords = (state: RootState) =>
  state.allUser.totalRecords;
  export const getAllUsersWithPaginationDetails = (state: RootState) =>
  state.allUser.data;
export default allUserSlice.reducer;
