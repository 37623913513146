import "./home.scss"
import logo from "../../assets/logo/auditLogo.png";
import { Modal } from "react-bootstrap";

type Props = {
    show: boolean;
    setShow: any;
    data: any;
    auditData: any;
};

const AuditRating = (props: Props) => {

    const handleClose = () => {
        props.setShow(false);
    };

    return (
        <>
            <Modal
                show={props.show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                // dialogClassName={"modal-90w"}
                size={"xl"}
            >
                <Modal.Header closeButton>
                    <Modal.Title className="fw-bold text-danger" style={{ fontSize: "2rem" }}>
                        {/* <div className="d-flex">
                            <img src={logo} width="70" height="50" alt="logo" />
                            <p className="fw-bold text-danger text-center">Ratings</p>
                        </div> */}
                        Ratings
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container border border-black border-3  pb-0">
                        {/* Header */}
                        <div className="row align-items-center p-3">
                            <div className="col-3 text-center">
                                <img src={logo} alt="Logo" style={{ width: '100px' }} /> {/* Replace with your logo */}
                            </div>
                            <div className="col-6 text-center">
                                <h2 className="text-danger fw-bold">Passion Audit Framework</h2>
                            </div>
                            <div className="col-3 text-end">
                                <img src={logo} alt="Second Logo" style={{ width: '80px' }} /> {/* Replace with second logo */}
                            </div>
                        </div>
                        {/* Content Section */}
                        {(props.auditData).map((audit: any) => {
                            console.log(audit)
                            const assessmentRating = parseFloat(audit.assessmentRating).toFixed(1);
                            const auditRating = parseFloat(audit.auditRating).toFixed(1);

                            return (
                                (audit.id === props.data.auditId && (
                                    <div className="row mt-3">

                                        <div className="col-md-6 p-0">
                                            <div className="row m-0 border-cell">
                                                <div className="col-4 fw-bold border-cell pa-b">Company Name</div>
                                                <div className="col-8 text-muted fw-medium border-cell pa-b">{props.data.organizationName}</div>
                                            </div>
                                            <div className="row m-0 border-cell">
                                                <div className="col-4 fw-bold border-cell pa-b">Auditor Company</div>
                                                <div className="col-8 text-muted fw-medium border-cell pa-b">{props.data.auditorCompanyName}</div>
                                            </div>
                                            <div className="row m-0 border-cell">
                                                <div className="col-4 fw-bold border-cell pa-b">Certified For</div>
                                                <div className="col-8 text-muted fw-medium border-cell pa-b">{props.data.auditorCompanyName}</div>
                                            </div>
                                            <div className="row m-0 border-cell">
                                                <div className="col-4 fw-bold border-cell pa-b">Audit Framework</div>
                                                <div className="col-8 text-muted fw-medium border-cell pa-b">{audit.auditFrameworkName}</div>
                                            </div>
                                            <div className="row m-0 border-cell">
                                                <div className="col-4 fw-bold border-cell pa-b">Expiry Date</div>
                                                <div className="col-8 text-muted fw-medium border-cell pa-b">{props.data.expiryDate}</div>
                                            </div>
                                        </div>

                                        {/* Right Column: Ratings */}
                                        <div className="col-md-6">
                                            <div className="row">
                                                <div className="col-12 text-center border-cell border-3 p-3">
                                                    <p className="fw-bold text-start">Assessment Rating</p>
                                                    <h1 className="display-1">{assessmentRating}/5</h1>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 text-center border-cell border-3 p-3">
                                                    <p className="fw-bold text-start">Audit Rating</p>
                                                    <h1 className="display-1">{auditRating}/5</h1>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )))
                        })}
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default AuditRating;