import { useDispatch } from "react-redux";
import { getLocalStorageData } from "../utils/LocalStorageService";
import { useEffect, useState } from "react";
import { alertMessage } from "../utils/AlertService";
import { useAuthenticateCertificateMutation } from "../redux-toolkit/services/pdfService";
import Loading from "./Loading";

type Props = {};

const CertificateAuthentication = (props: Props) => {
    const localUserObj = getLocalStorageData("localUser");
    const dispatch = useDispatch();

    //states
    const [file, setFile]: any = useState<File | null>(null);
    const [fileName, setFileName]: any = useState("");
    const [uploadxlsx, uploadxlsxResponse] = useAuthenticateCertificateMutation();

    //getData

    //selector


    // upload file...............................
    const handleFileChange = (e: any) => {
        // const fileName = e.target.files[0].name;
        // const extension = fileName.lastIndexOf(".") + 1;
        // const extFile = fileName.substr(extension, fileName.length).toLowerCase();

        // if (extFile !== "xlsx") {
        //     alertMessage("Only xlsx are allowed!", "error");
        // } else {
            setFile(e.target.files[0]);
            setFileName(e.target.files[0].name)
        // }
    };

    const handleSubmit = () => {
        const fileName = file.name;
        const extension = fileName.lastIndexOf(".") + 1;
        const extFile = fileName.substr(extension, fileName.length).toLowerCase();

        if (extFile !== "xlsx") {
            alertMessage("Only xlsx are allowed!", "error");
        } else { 
            uploadxlsx({
                file: file,
                token: localUserObj.token
            })
        }
    }

    useEffect(() => {
        if (uploadxlsxResponse.isSuccess) {
            let msg: any = uploadxlsxResponse?.data.message;
            if(uploadxlsxResponse?.data?.status === "success") {
                alertMessage(uploadxlsxResponse?.data.message ? msg : "Certificate authenticated successfully", "success");
            } else if (uploadxlsxResponse?.data?.status === "rejected") {
                alertMessage(uploadxlsxResponse?.data.message ? msg : "Certificate is not authenticated", "error");
            }
        } else if (uploadxlsxResponse.isError) {
            alertMessage("Error while authenticating", "error");
        }
    }, [uploadxlsxResponse.isSuccess, uploadxlsxResponse.isError])

    return (
        <div>
            {uploadxlsxResponse.isLoading ? (
                <Loading />
            ) : (
                ""
            )}
            <div className="fw-bold fs-4 mt-2">
                Authenticate Certificate
            </div>
            <hr />
            <div className="mt-2">
                <p className="fw-semibold">Upload Xlsx</p>

                <div className="d-flex">
                    <input type="file" hidden id="fileUpload" onChange={handleFileChange} />
                    <label htmlFor="fileUpload" className="btn btn-danger me-2" style={{ width: "20%" }}>Upload file</label>
                    <p className="m-0">{fileName}</p>
                </div>
                <div className="d-flex">
                    <div style={{ color: "red" }}>*</div>
                    <p>Allowed formats - Xlsx</p>
                </div>
            </div>

            <button
                type="submit"
                className={`btn btn-success btn-block mt-3 mx-2 btn-width`}
                style={{ width: "30%" }}
                onClick={handleSubmit}
            >
                Authenticate
            </button>
        </div>
    );

};

export default CertificateAuthentication;