import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {userManagementBaseUrl, userManagement  } from "../endpoints";
// Define a service using a base URL and expected endpoints
export const allUserGroupService = createApi({
    reducerPath: "allUserGroupService",
    baseQuery: fetchBaseQuery({ baseUrl: userManagementBaseUrl }),
    tagTypes: ["allUserGroup"],
    endpoints: (builder) => ({
        getAllUserGroup: builder.mutation({
            query(body) {
                return {
                    url: `${userManagement.userGroupController.getAllUserGroup}`,
                    method: "GET",
                    // headers: { Authorization: `Bearer ${body.token}`},
                    // body: body.payload,
                };
            },
        }),
        updateUserGroup: builder.mutation({
            query(body) {
                return {
                    // url: `${userManagement.userGroupController.updateUserGroup}/${body.id}`,
                    url: `${userManagement.userGroupController.updateUserGroup}`,
                    method: "PUT",
                    headers: { Authorization: `Bearer ${body.token}`},
                    body: body.payload,
                };
            },
        }),
        deleteUserGroupById: builder.mutation({
            query(body) {
                return {
                    url: `${userManagement.userGroupController.deleteUserGroupById}/${body.id}`,
                    method: "DELETE",
                    headers: { Authorization: `Bearer ${body.token}`},
                };
            },
        }),
        getUserGroupById: builder.mutation({
            query(body) {
                return {
                    url: `${userManagement.userGroupController.getUserGroupById}/${body.id}`,
                    method: "GET",
                    headers: { Authorization: `Bearer ${body.token}`},
                };
            },
        }),
        saveUserGroup: builder.mutation({
            query(body) {
                return {
                    url: `${userManagement.userGroupController.saveUserGroup}`,
                    method: "POST",
                    headers: { Authorization: `Bearer ${body.token}`},
                    body: body.payload,
                };
            },
        }),
    }),
});
export const {
    useGetAllUserGroupMutation,
    useSaveUserGroupMutation,
    useDeleteUserGroupByIdMutation,
    useGetUserGroupByIdMutation,
    useUpdateUserGroupMutation,
} = allUserGroupService;