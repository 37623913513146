import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
export interface AllUserGroupState {
    data: [] | null | any;
}
const initialState: AllUserGroupState = {
    data: null,
};
export const allUserGroupSlice = createSlice({
    name: 'allUserGroup',
    initialState,
    reducers: {
        setAllUserGroup: (state, action) => {
            state.data = action.payload.data;
        },
        reset: (state) => {
            state = initialState;
        },
    },
});
// Action creators are generated for each case reducer function
export const { setAllUserGroup, reset } = allUserGroupSlice.actions;
export const getAllUserGroupDetails = (state: RootState) => state.allUserGroup.data;
export default allUserGroupSlice.reducer;