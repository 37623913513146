import { useNavigate, useParams } from "react-router-dom";
import { getLocalStorageData } from "../../utils/LocalStorageService";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { ACTION_TYPE, REPORT_NAME, REPORT_PATH } from "../../constant/reportType";
import { Button } from "react-bootstrap";
import { Switch, Table } from "antd";
import { ORGANIZATION_MASTER_COLUMN, USER_COLUMN, USER_GROUP_COLUMN, USER_ROLE_COLUMN } from "../../constant/AppConstant";
import { BsPencilSquare, BsPlus } from "react-icons/bs";
import { alertMessage } from "../../utils/AlertService";
import MasterReportData from "./MasterReportData";
import { useAppSelector } from "../../redux-toolkit/hooks";
import Loading from "../Loading";
import { useDeleteOrganizationMasterMutation, useGetAllOrganizationMasterMutation } from "../../redux-toolkit/services/organizationMasterService";
import { getAllOrganizationMasterDetails, getOrganizationMasterTotalRecords, setOrganizationMaster } from "../../redux-toolkit/slices/organizationMasterSlice";
import { useDeleteUserByIdMutation, useGetAllUserMutation } from "../../redux-toolkit/services/allUserService";
import { getAllUsersTotalRecords, getAllUsersWithPaginationDetails, setAllUserWithPagination } from "../../redux-toolkit/slices/allUserSlice";
import UserMasterReportData from "./UserMasterReportData";
import { useDeleteUserRoleByIdMutation, useGetAllUserRoleMutation } from "../../redux-toolkit/services/userRoleService";
import { useDeleteUserGroupByIdMutation, useGetAllUserGroupMutation } from "../../redux-toolkit/services/userGroupService";
import { getAllUserRoleDetails, setAllUserRole } from "../../redux-toolkit/slices/allUserRoleSlice";
import { getAllUserGroupDetails, setAllUserGroup } from "../../redux-toolkit/slices/allUserGroupSlice";

type Props = {};
const UserMasterReports = (props: Props) => {

    const params = useParams();
    const token: any = getLocalStorageData("localUser").token;
    const dispatch = useDispatch();

    //state
    const [actionType, setActionType] = useState(ACTION_TYPE.ADD_ACTION) as any;
    const [reportName, setReportName]: any = useState();
    const [currentpage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [show, setShow] = useState(false);
    const [selectedRow, setSelectedRow]: any = useState([]);
    const [columData, setColumnData]: any = useState();
    const [userDataSource, setUserDataSource]: any = useState([]);
    const [orgDataSource, setOrgDataSource]: any = useState([]);
    const [userRoleDataSource, setUserRoleDataSource]: any = useState([]);
    const [userGroupDataSource, setUserGroupDataSource]: any = useState([]);
    const [call, setCall] = useState("N");

    //getMutations
    const [getAllUser, getAllUserResponse] = useGetAllUserMutation();
    const [getAllOrganizationMaster, organizationMasterResponse] = useGetAllOrganizationMasterMutation();
    const [getAllUserRole, getAllUserRoleResponse] = useGetAllUserRoleMutation();
    const [getAllUserGroup, getAllUserGroupResponse] = useGetAllUserGroupMutation();

    //get by search

    //delete mutation
    const [deleteUser, deleteUserResponse] = useDeleteUserByIdMutation();
    const [deleteOrganizationMaster, deleteOrganizationMasterResponse] = useDeleteOrganizationMasterMutation();
    const [deleteUserRole, deleteUserRoleResponse] = useDeleteUserRoleByIdMutation();
    const [deleteUserGroup, deleteUserGroupResponse] = useDeleteUserGroupByIdMutation();

    //totalRecords
    const totalOrganizationMaster: any = useAppSelector(getOrganizationMasterTotalRecords);
    const totalUsers: any = useAppSelector(getAllUsersTotalRecords)

    //selector
    const organizationMasterData: any = useAppSelector(getAllOrganizationMasterDetails);
    const allUser: any = useAppSelector(getAllUsersWithPaginationDetails);
    const allUserRole: any = useAppSelector(getAllUserRoleDetails);
    const allUserGroup: any = useAppSelector(getAllUserGroupDetails);

    useEffect(() => {
        switch (params.reportType) {
            case REPORT_PATH.USER_MASTER:
                setReportName(REPORT_NAME.USER_MASTER)
                break;
            case REPORT_PATH.ORGANIZATION_MASTER:
                setReportName(REPORT_NAME.ORGANIZATION_MASTER)
                break;
            case REPORT_PATH.USER_GROUP:
                setReportName(REPORT_NAME.USER_GROUP)
                break;
            case REPORT_PATH.USER_ROLE:
                setReportName(REPORT_NAME.USER_ROLE)
                break;
            default:
        }
    }, [params.reportType]);

    useEffect(() => {
        const temp: any = [];
        const tempAction: any = {
            title: "Action",
            dataIndex: "action",
            key: "name",
            fixed: "right",
            width: 120,
            render: (index: any, record: any) => {
                return (
                    // for delete switch button
                    <div className="d-flex justify-content-between">
                        <div className="px-2 pe-auto">
                            <Switch
                                className={`${record.status === true ||
                                    record.enabled === true ||
                                    record.isEnabled === true ? "bg-danger" : "bg-secondry"}`}
                                checked={
                                    record.status === true ||
                                        record.enabled === true ||
                                        record.isEnabled === true
                                        ? true
                                        : false
                                }
                                onClick={() => {
                                    onDeleteData(record);
                                }}
                            />
                        </div>
                        {record.status === true ||
                            record.enabled === true ||
                            record.isEnabled === true ? (
                            <div
                                className="px-2 pe-auto"
                                onClick={() => {
                                    handleOpenForm(record);
                                }}
                            >
                                <BsPencilSquare size={18} />
                            </div>
                        ) : null}
                    </div>
                );
            },
        };

        if (reportName === REPORT_NAME.ORGANIZATION_MASTER && ORGANIZATION_MASTER_COLUMN) {
            ORGANIZATION_MASTER_COLUMN.map((item: any) => {
                temp.push(item);
            });
            temp.push(tempAction);
            setColumnData(temp);
        } else if (reportName === REPORT_NAME.USER_MASTER && USER_COLUMN) {
            USER_COLUMN.map((item: any) => {
                temp.push(item);
            });
            temp.push(tempAction);
            setColumnData(temp);
        } else if (reportName === REPORT_NAME.USER_GROUP && USER_GROUP_COLUMN) {
            USER_GROUP_COLUMN.map((item: any) => {
                temp.push(item);
            });
            temp.push(tempAction);
            setColumnData(temp);
        } else if (reportName === REPORT_NAME.USER_ROLE && USER_ROLE_COLUMN) {
            USER_ROLE_COLUMN.map((item: any) => {
                temp.push(item);
            });
            temp.push(tempAction);
            setColumnData(temp);
        }
    }, [reportName, params.reportType]);

    useEffect(() => {
        if (reportName === REPORT_NAME.ORGANIZATION_MASTER) {
            getAllOrganizationMaster({
                token: token,
                payload: {
                    size: pageSize,
                    page: currentpage - 1,
                    orderBy: "asc"
                }
            });
        } else if (reportName === REPORT_NAME.USER_MASTER) {
            getAllUser({
                token: token,
                payload: {
                    size: pageSize,
                    page: currentpage - 1,
                    orderBy: "asc"
                }
            });
        } else if (reportName === REPORT_NAME.USER_GROUP) {
            getAllUserGroup({ token: token });
        } else if (reportName === REPORT_NAME.USER_ROLE) {
            getAllUserRole({ token: token });
        }
    }, [reportName]);

    useEffect(() => {
        if (call === "Y") {
            if (reportName === REPORT_NAME.ORGANIZATION_MASTER) {
                getAllOrganizationMaster({
                    token: token,
                    payload: {
                        size: pageSize,
                        page: currentpage - 1,
                        orderBy: "asc"
                    }
                });
                setCall("N");
            } else if (reportName === REPORT_NAME.USER_MASTER) {
                getAllUser({
                    token: token,
                    payload: {
                        size: pageSize,
                        page: currentpage - 1,
                        orderBy: "asc"
                    }
                });
                setCall("N");
            } else if (reportName === REPORT_NAME.USER_GROUP) {
                getAllUserGroup({ token: token });
                setCall("N");
            } else if (reportName === REPORT_NAME.USER_ROLE) {
                getAllUserRole({
                    token: token,
                });
                setCall("N");
            }
        }
    }, [call]);

    const paginationChange = (page: number, pageSize: number) => {
        if (reportName === REPORT_NAME.ORGANIZATION_MASTER) {
            getAllOrganizationMaster({
                token: token,
                payload: {
                    size: pageSize,
                    page: page - 1,
                    orderBy: "asc"
                }
            });
        } else if (reportName === REPORT_NAME.USER_MASTER) {
            getAllUser({
                token: token,
                payload: {
                    size: pageSize,
                    page: page - 1,
                    orderBy: "asc"
                }
            });
        }
        setCurrentPage(page);
        setPageSize(pageSize);
    };

    const handleShow = () => {
        setActionType(ACTION_TYPE.ADD_ACTION);
        setShow(true)
    };

    //delete..........
    const onDeleteData = (record: any) => {
        if (reportName === REPORT_NAME.ORGANIZATION_MASTER) {
            deleteOrganizationMaster({
                id: record.id,
                token: token
            })
        } else if (reportName === REPORT_NAME.USER_MASTER) {
            deleteUser({
                id: record.id,
                token: token
            })
        } else if (reportName === REPORT_NAME.USER_ROLE) {
            deleteUserRole({
                token: token,
                id: record.id,
            });
        } else if (reportName === REPORT_NAME.USER_GROUP) {
            deleteUserGroup({
                token: token,
                id: record.id,
            });
        }
    };

    const handleOpenForm = (record: any) => {
        setActionType(ACTION_TYPE.UPDATE_ACTION);
        setSelectedRow(record);
        setShow(true);
    };

    //get allOrganizationMaster.......
    useEffect(() => {
        if (organizationMasterResponse.isSuccess) {
            dispatch(setOrganizationMaster(organizationMasterResponse));
        } else if (organizationMasterResponse.isError) {
            alertMessage("Error while fetching organizations", "error");
            // setDatasource([]);
        }
    }, [organizationMasterResponse.isSuccess, organizationMasterResponse.isError]);

    useEffect(() => {
        if (getAllUserResponse.isSuccess) {
            dispatch(setAllUserWithPagination(getAllUserResponse));
        } else if (getAllUserResponse.isError) {
            alertMessage("Error while fetching users", "error");
            // setDatasource([]);
        }
    }, [getAllUserResponse.isSuccess, getAllUserResponse.isError]);

    useEffect(() => {
        if (getAllUserRoleResponse.isSuccess) {
            dispatch(setAllUserRole(getAllUserRoleResponse));
        } else if (getAllUserRoleResponse.isError) {
            alertMessage("Error fetching user role", "error");
        }
    }, [getAllUserRoleResponse.isSuccess, getAllUserRoleResponse.isError]);

    useEffect(() => {
        if (getAllUserGroupResponse.isSuccess) {
            dispatch(setAllUserGroup(getAllUserGroupResponse));
        } else if (getAllUserGroupResponse.isError) {
            alertMessage("Error fetching user group", "error");
        }
    }, [getAllUserGroupResponse.isSuccess, getAllUserGroupResponse.isError]);

    //data

    useEffect(() => {
        if (organizationMasterData && reportName === REPORT_NAME.ORGANIZATION_MASTER) {
            setOrgDataSource(organizationMasterData);
        }
    }, [organizationMasterData]);

    useEffect(() => {
        if (allUser) {
            buildUserData(allUser);
        }
    }, [allUser]);

    useEffect(() => {
        if (allUserRole) {
            setUserRoleDataSource(allUserRole);
        }
    }, [allUserRole]);

    useEffect(() => {
        if (allUserGroup) {
            setUserGroupDataSource(allUserGroup);
        }
    }, [allUserGroup]);

    const buildUserData = (data: any) => {
        let temp: any = [];
        data.forEach((element: any) => {
            // let payload =
            temp.push({
                id: element.id,
                name:
                    (element.firstName === null ? "" : element.firstName) +
                    " " +
                    (element.lastName === null ? "" : element.lastName),
                phoneNumber: (element.phoneNumber === null || element.phoneNumber === "") ? "-" : element.phoneNumber,
                email: element.email === null ? "-" : element.email,
                userRole: element.userRole.name === null ? "-" : element.userRole.name,
                userRoleId: element.userRole.id === null ? "-" : element.userRole.id,
                userGroup:
                    element.userGroup.name === null ? "-" : element.userGroup.name,
                userGroupId: element.userGroup.id === null ? "-" : element.userGroup.id,
                countryId: element.countryId === null ? "-" : element.countryId,
                stateId: element.stateId === null ? "-" : element.stateId,
                enabled: element.enabled,
            });
        });
        setUserDataSource(temp);
    };

    //deleteResponse responses

    useEffect(() => {
        if (deleteOrganizationMasterResponse.isSuccess) {
            alertMessage("Organization updated successfully", "success");
            setCall("Y");
        } else if (deleteOrganizationMasterResponse.isError) {
            alertMessage("Error while deleting", "error");
        }
    }, [deleteOrganizationMasterResponse.isSuccess, deleteOrganizationMasterResponse.isError]);

    useEffect(() => {
        if (deleteUserResponse.isSuccess) {
            alertMessage("User status updated successfully", "success");
            setCall("Y");
        } else if (deleteUserResponse.isError) {
            alertMessage("Error while deleting", "error");
        }
    }, [deleteUserResponse.isSuccess, deleteUserResponse.isError]);

    useEffect(() => {
        if (deleteUserRoleResponse.isSuccess) {
            alertMessage("User role status updated successfully", "success");
            setCall("Y");
        } else if (deleteUserRoleResponse.isError) {
            alertMessage("Error while deleting", "error");
        }
    }, [deleteUserRoleResponse.isSuccess, deleteUserRoleResponse.isError]);

    useEffect(() => {
        if (deleteUserGroupResponse.isSuccess) {
            setCall("Y");
            alertMessage("User group status updated successfully", "success");
        } else if (deleteUserGroupResponse.isError) {
            alertMessage("Error while deleting", "error");
        }
    }, [deleteUserGroupResponse.isSuccess, deleteUserGroupResponse.isError]);

    const derivedDataSource = (() => {
        switch (reportName) {
            case REPORT_NAME.USER_MASTER:
                return userDataSource; // Assuming this is the data for the USER_MASTER report
            case REPORT_NAME.ORGANIZATION_MASTER:
                return orgDataSource; // Data for ORGANIZATION_MASTER report
            case REPORT_NAME.USER_ROLE:
                return userRoleDataSource;
            case REPORT_NAME.USER_GROUP:
                return userGroupDataSource;
            default:
                return []; // Fallback or default data
        }
    })();

    return (
        <div className="mt-2 overflow-y-auto">
            <div className="d-flex align-items-center justify-content-between flex-sm-row flex-column mb-2">
                <div className="d-flex align-items-center w-100 px-2">
                    <h5
                        className="m-0 px-0 d-flex align-items-center text-cm-active-primary1"
                        style={{ fontWeight: "700" }}
                    >
                        {reportName === REPORT_NAME.USER_ROLE ? "Subscriber" : reportName}
                    </h5>
                </div>
                <div className="d-flex align-items-center justify-content-start justify-content-sm-end cursor-pointer w-100">
                    <div onClick={handleShow} className="d-flex px-2 align-items-center mt-1">
                        <Button
                            variant="danger"
                            className="d-flex align-items-center me-0 text-nowrap"
                        // onClick={() => {
                        //     setActionType(ACTION_TYPE.ADD_ACTION);
                        // }}
                        >
                            <BsPlus />
                            Add
                        </Button>
                    </div>
                    {getAllUserResponse.isLoading ||
                        organizationMasterResponse.isLoading ||
                        getAllUserRoleResponse.isLoading ||
                        getAllUserGroupResponse.isLoading ||
                        deleteUserResponse.isLoading ||
                        deleteOrganizationMasterResponse.isLoading ||
                        deleteUserRoleResponse.isLoading ||
                        deleteUserGroupResponse.isLoading ? (
                        <Loading />
                    ) : (
                        ""
                    )}

                    {show && (
                        <UserMasterReportData
                            show={show}
                            setShow={setShow}
                            actionType={actionType}
                            reportName={reportName}
                            data={selectedRow}
                            setCall={setCall}
                        />
                    )}
                </div>
            </div>
            <div className="mt-3 my-2 w-100 overflow-x-auto rounded-3">
                <Table
                    bordered={true}
                    rowKey={"id"}

                    pagination={{
                        total: (() => {
                            switch (reportName) {
                                case REPORT_NAME.USER_MASTER:
                                    return totalUsers;
                                case REPORT_NAME.ORGANIZATION_MASTER:
                                    return totalOrganizationMaster;
                                default:
                                    return [];
                            }
                        })(),
                        // hideOnSinglePage: true,  
                        responsive: true,
                        current: currentpage,
                        // showSizeChanger: true,  
                        pageSizeOptions: [10, 20, 30],
                        onChange: (page, pageSize) => {
                            paginationChange(page, pageSize);
                        },
                        pageSize: pageSize,
                    }}
                    columns={columData}
                    dataSource={derivedDataSource}
                />
            </div>
        </div>
    );
};
export default UserMasterReports;