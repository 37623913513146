import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { userManagementBaseUrl, userManagement } from "../endpoints";
// Define a service using a base URL and expected endpoints
export const allUserService = createApi({
    reducerPath: "allUserService",
    baseQuery: fetchBaseQuery({ baseUrl: userManagementBaseUrl }),
    tagTypes: ["allUser"],
    endpoints: (builder) => ({
        getAllUser: builder.mutation({
            query(body) {
                return {
                    url: `${userManagement.usersController.getAllUser}/${body.payload.page}/${body.payload.size}?orderBy=${body.payload.orderBy}`,
                    method: "GET",
                    // headers: { Authorization: `Bearer ${body.token}`},
                    // body: body.payload,
                };
            },
        }),
        getAllUserWithSearch: builder.mutation({
            query(body) {
                return {
                    url: `${userManagement.usersController.getAllUsersWithSearch}`,
                    method: "POST",
                    headers: { Authorization: `Bearer ${body.token}`},
                    body: body.payload,
                };
            },
        }),
        updateUser: builder.mutation({
            query(body) {
                return {
                    url: `${userManagement.usersController.updateUser}/${body.id}`,
                    method: "PUT",
                    // headers: { Authorization: `Bearer ${body.token}`},
                    body: body.payload,
                };
            },
        }),
        deleteUserById: builder.mutation({
            query(body) {
                return {
                    url: `${userManagement.usersController.deleteUserById}/${body.id}`,
                    method: "DELETE",
                };
            },
        }),
        getUserById: builder.mutation({
            query(body) {
                return {
                    url: `${userManagement.usersController.getUserById}/${body.id}`,
                    method: "GET",
                    // headers: { Authorization: `Bearer ${body.token}`},
                };
            },
        }),
        saveUser: builder.mutation({
            query(body) {
                return {
                    url: `${userManagement.usersController.saveUser}`,
                    method: "POST",
                    // headers: { Authorization: `Bearer ${body.token}`},
                    body: body.payload,
                };
            },
        }),
        resetPassword: builder.mutation({
            query(body) {
                return {
                    url: `${userManagement.usersController.resetPassword}`,
                    method: "POST",
                    // headers: { Authorization: `Bearer ${body.token}`},
                    body: body,
                };
            },
        }),
        forgotPassword: builder.mutation({
            query(body) {
              return {
                url: `${userManagement.userController.forgotPassword}/${body.email}`,
                method: "PUT",
                body: body.payload
              };
            }
          }),
    }),
});
export const {
    useGetAllUserMutation,
    useSaveUserMutation,
    useDeleteUserByIdMutation,
    useGetUserByIdMutation,
    useUpdateUserMutation,
    useForgotPasswordMutation,
    useResetPasswordMutation,
    useGetAllUserWithSearchMutation,
} = allUserService;