import { createSlice } from "@reduxjs/toolkit";

export interface AuthState {
  data: {} | null;
  id: string | number | null;
  // jwtToken: string | null;
}

const initialState: AuthState = {
  data: null,
  id: null,
  // jwtToken: null
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthState: (state, action) => {
      state.data = action.payload;
      state.id = action.payload.id;
      // state.jwtToken = action.payload.jwtToken;
    },
    resetAuthState: (state) => {
      state = initialState;
    }
  }
});

// Action creators are generated for each case reducer function
export const { setAuthState, resetAuthState } = authSlice.actions;
export const getAuthData = (state: any) => state.auth.data;
export const getAuthId = (state: any) => state.auth.id;
export const getAuthJwtToken = (state: any) => state.auth.jwtToken;

export default authSlice.reducer;
